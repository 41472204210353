@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0;
    padding: 0;
}

html {
    background: #1e1a20;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.section {
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: "Cabin", sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd) {
    justify-content: flex-end;
}
